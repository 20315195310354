import "./importColors.css";
import { ptBR as ptBRCore } from "@mui/x-data-grid/locales";
import Box from "@mui/material/Box";
import {
  DataGridPremium,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarExportContainer,
  GridCsvExportMenuItem,
  GridPrintExportMenuItem,
  GridExcelExportMenuItem,
} from "@mui/x-data-grid-premium";
import Loading from "../../components/loading/loading";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getProducts } from "../../redux/apiCalls";
import { useState } from "react";
import * as XLSX from "xlsx";
import { userRequest } from "../../requestMethods";
import Swal from "sweetalert2";
import { generateFileUrl } from "../../requestMethods";
import Button from "@mui/material/Button";
import { publicRequestSt } from "../../requestMethods";

export default function ImportColors() {
  const dispatch = useDispatch();
  const [dataXLS, setDataXLS] = useState([]);
  const [companyId, setCompanyId] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [companies, setCompanies] = useState();

  const columns = [
    {
      field: "rgb",
      headerName: "Hex",
      headerClassName: "dataGridMuiHeader",
      width: 80,
      align: "left",
      display: "flex",
      renderCell: (params) => {
        return (
          <Box
            sx={{
              height: "40px",
              width: "40px",
              backgroundColor: params.value,
              borderRadius: "5px",
            }}
          ></Box>
        );
      },
    },
    {
      field: "ref",
      headerName: "Referência Hex",
      headerClassName: "dataGridMuiHeader",
      width: 200,
      align: "left",
    },
    {
      field: "color",
      headerName: "Cor",
      headerClassName: "dataGridMuiHeader",
      width: 200,
      align: "left",
    },
  ];

  const addXLS = async () => {
    try {
      if (dataXLS.length > 0) {
        setIsLoading(true);
        const colors = Array.from(dataXLS).map((item) => ({
          ref: item.color,
          rgb: item.ref,
          label: item.color,
        }));

        const res = await userRequest.post(`/standard/importColors`, colors);
        setIsLoading(false);
        if (Object.keys(res.data).length > 0) {
          Swal.fire("Parabéns!", "Cores inseridas com sucesso!", "success");
        } else {
          Swal.fire(
            "Atenção!",
            "Favor verificar as cores, nenhuma cor inserida!",
            "warning"
          );
        }
      } else {
        Swal.fire("Atenção!", "Favor selecionar um aquivo!", "warning");
      }
    } catch (err) {
      setIsLoading(false);
      console.log("error:", err);
    }
  };

  const handleFileUpload = (e) => {
    const reader = new FileReader();
    reader.readAsBinaryString(e.target.files[0]);
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: "binary" });

      if (validaFile(workbook)) {
        const index = workbook.SheetNames.findIndex((item) => item === "Cores");
        const sheetName = workbook.SheetNames[index];
        const sheet = workbook.Sheets[sheetName];

        sheet["A1"].w = "ref";
        sheet["B1"].w = "color";

        const parsedData = XLSX.utils.sheet_to_json(sheet, { raw: false });
        const newParseData = parsedData.map((item) => ({
          rgb: item.ref ? item.ref.toUpperCase() : null,
          ref: item.ref ? item.ref.toUpperCase() : null,
          color: item.color ? item.color.trim().toUpperCase() : null,
        }));

        setDataXLS(newParseData);
      } else {
        Swal.fire("Erro!", "Estrutura do arquivo modificada.", "warning");
      }
    };
  };

  const validaFile = (workbook) => {
    const index = workbook.SheetNames.findIndex((item) => item === "Cores");
    const sheetName = workbook.SheetNames[index];
    const sheet = workbook.Sheets[sheetName];

    if (index === -1) {
      return false;
    }
    if (sheet["A1"].v !== "Referencia Hex") {
      return false;
    }
    if (sheet["B1"].v !== "Cor") {
      return false;
    }

    return true;
  };
  const handleChangeCompany = (e) => {
    setCompanyId(e.target.value);
  };
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }
  const GridToolbarExport = ({
    csvOptions,
    printOptions,
    excelOptions,
    ...other
  }) => (
    <GridToolbarExportContainer {...other}>
      <GridCsvExportMenuItem options={csvOptions} />
      <GridExcelExportMenuItem options={excelOptions} />
    </GridToolbarExportContainer>
  );
  let downalodFIle = generateFileUrl("ImportFiles/ImportarCores.xlsx");
  return (
    <div className="div_custom_">
      <div className="div_custom_Container">
        <div className="div_custom_UpdateList">
          <div>
            <input
              id="selecao-arquivo"
              type="file"
              accept=".xlsx, .xls"
              onChange={handleFileUpload}
            />
          </div>
          <div>
            <Button
              className="custom_add_top"
              size="small"
              variant="outlined"
              color="secondary"
              onClick={addXLS}
            >
              Importar Excel
            </Button>
          </div>
          <div>
            <labe>Informações para importação de cores</labe>
            <br></br>
          </div>
          <div className="custom_label">
            <labe>
              1. O arquivo deve ser utilizado na mesma estrutura de download.
              <a href={downalodFIle} download>
                Download Arquivo
              </a>
            </labe>
          </div>
          <div>
            <br></br>
            {isLoading ? (
              <Loading />
            ) : (
              <DataGridPremium
                autoHeight
                sx={{
                  fontFamily: "Manrope",
                  fontWeight: "500",
                  border: "0px",
                }}
                rowHeight={45}
                localeText={
                  ptBRCore.components.MuiDataGrid.defaultProps.localeText
                }
                slots={{ toolbar: CustomToolbar }}
                slotProps={{
                  panel: {
                    sx: {
                      top: "15px !important",
                      left: "50px !important",
                      position: "fixed !important",
                    },
                  },
                }}
                getRowId={(row) => row.ref + row.size + row.stock}
                rows={dataXLS}
                disableSelectionOnClick
                columns={columns}
                pageSize={8}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
