import { DeleteOutline, Edit } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import {
  DataGridPremium,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarExportContainer,
  GridCsvExportMenuItem,
  GridPrintExportMenuItem,
  GridExcelExportMenuItem,
} from "@mui/x-data-grid-premium";
import { ptBR as ptBRCore } from "@mui/x-data-grid/locales";
import * as React from "react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useCompany } from "../../components/context/CompanyContext";
import Loading from "../../components/loading/loading";
import "../../global.css";
import { userRequest } from "../../requestMethods";
import { formatTimeDDMMYYY } from "../../util/helper";
import Switch from "@mui/material/Switch";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";

export default function CompanyList() {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getCompanies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCompanies = async () => {
    try {
      setIsLoading(true);
      const res = await userRequest.get(`company`);

      setData(
        res.data.map((item) => ({
          companyId: item.companyId,
          name: item.name,
          layout: typeLayout(JSON.parse(item.configUI)),
          configUI: JSON.parse(item.configUI),
          planContract: item.planContract.plan,
          slug: item.slug,
          createdAt: item.createdAt,
          enable: item.enable,
        }))
      );
    } catch (error) {}
    setIsLoading(false);
  };

  const enableCompany = async (companyId, enable) => {
    const res = await userRequest.put(`company/${companyId}`, {
      enable: enable,
    });

    if (res.data > 0) {
      getCompanies();
    } else {
      Swal.fire("Atenção!", "Erro ao inativar usuário!", "error");
    }
  };
  const onButtonDeleteClick = (e, companyId) => {
    if (e.target.checked) {
      enableCompany(companyId, true);
    } else {
      enableCompany(companyId, false);
    }
  };

  const onButtonEditarClick = (e, row) => {
    nav(window.location.pathname.replace("tabconfigs", "users") + "/" + row);
  };

  const nav = useNavigate();
  const addRow = () => {
    nav(`../onboarding`);
  };

  function typeLayout(configUi) {
    if (configUi) {
      switch (configUi.layout) {
        case "category":
          return "1 Categoria";
        case "highligh_category":
          return "Highligh + 1 Categoria";
        case "multi_category":
          return "2 Categorias";
        case "caos":
          return "Caos";
        default:
          return "Atacado";
      }
    }
    return "Atacado";
  }
  function translateLayout(lay) {
    switch (lay) {
      case "1 Categoria":
        return "category";
      case "Highligh + 1 Categoria":
        return "highligh_category";
      case "2 Categorias":
        return "multi_category";
      case "Caos":
        return "caos";
      default:
        return "default";
    }
  }

  const status = [
    "Atacado",
    "1 Categoria",
    "Highligh + 1 Categoria",
    "2 Categorias",
    "Caos",
  ];
  const useFakeMutation = () => {
    return React.useCallback(
      (obj) =>
        new Promise(async (resolve, reject) => {
          const res = await userRequest.put(`${obj.companyId}/company/`, {
            configUI: obj.configUI,
          });

          if (Object.keys(res.data).length > 0) {
            resolve(obj);
          } else {
            reject(new Error("Erro atualizar status"));
          }
        }),
      []
    );
  };
  const mutateRow = useFakeMutation();
  const AlterarStatus = React.useCallback(async (obj) => {
    let rtObj = {
      companyId: obj.companyId,
      configUI: {
        imgLogo:
          obj.configUI && obj.configUI.imgLogo ? obj.configUI.imgLogo : null,
        isRetail:
          obj.configUI && obj.configUI.isRetail ? obj.configUI.isRetail : null,
        layout: translateLayout(obj.layout),
      },
    };

    const res = await mutateRow(rtObj);
    return obj;
  }, []);
  const handleProcessRowUpdateError = React.useCallback((error) => {
    console.log("error", error);
  }, []);

  const columns = [
    {
      field: "companyId",
      headerName: "ID da Empresa",
      width: 150,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
    },
    {
      field: "name",
      headerName: "Nome",
      width: 200,
      align: "left",
      headerAlign: "left",
      headerClassName: "dataGridMuiHeader",
    },
    {
      field: "layout",
      headerName: "Layout",
      width: 200,
      align: "left",
      headerAlign: "left",
      headerClassName: "dataGridMuiHeader",
      editable: true,
      type: "singleSelect",
      valueOptions: status,
    },
    {
      field: "planContract",
      headerName: "Plano Contratado",
      width: 200,
      align: "left",
      headerAlign: "left",
      headerClassName: "dataGridMuiHeader",
    },
    {
      field: "slug",
      headerName: "urlEmpresa",
      width: 120,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
    },
    {
      field: "createdAt",
      headerName: "Criada em",
      width: 200,
      align: "left",
      headerClassName: "dataGridMuiHeader",
      valueFormatter: (value) => {
        if (value === null) {
          return "";
        }
        return `${formatTimeDDMMYYY(value)}`;
      },
      valueGetter: (value) => value && new Date(value),
    },
    {
      field: "enable",
      headerName: "",
      width: 80,
      align: "center",
      headerClassName: "dataGridMuiHeader",
      headerAlign: "center",
      renderCell: (params) => {
        if (params.row.enable) {
          return (
            <Switch
              defaultChecked={true}
              onChange={(e) => onButtonDeleteClick(e, params.row.companyId)}
            />
          );
        } else {
          return (
            <Switch
              defaultChecked={false}
              onChange={(e) => onButtonDeleteClick(e, params.row.companyId)}
            />
          );
        }
      },
    },
  ];
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }
  const GridToolbarExport = ({
    csvOptions,
    printOptions,
    excelOptions,
    ...other
  }) => (
    <GridToolbarExportContainer {...other}>
      <GridCsvExportMenuItem options={csvOptions} />
      <GridExcelExportMenuItem options={excelOptions} />
    </GridToolbarExportContainer>
  );
  return (
    <div className="div_custom_">
      {isLoading ? (
        <Loading />
      ) : (
        <div className="div_custom_Container">
          <div className="div_custom_UpdateRight">
            <br></br>
            <Button
              className="custom_add_top"
              variant="outlined"
              color="secondary"
              startIcon={<AddIcon />}
              onClick={addRow}
              size="small"
            >
              Adicionar Empresa
            </Button>
          </div>
          <div className="div_custom_UpdateLeft">
            <div style={{ height: 900 }}>
              <DataGridPremium
                autoHeight
                sx={{
                  fontFamily: "Manrope",
                  fontWeight: "500",
                  border: "0px",
                }}
                rowHeight={45}
                localeText={
                  ptBRCore.components.MuiDataGrid.defaultProps.localeText
                }
                slots={{ toolbar: CustomToolbar }}
                slotProps={{
                  panel: {
                    sx: {
                      top: "15px !important",
                      left: "50px !important",
                      position: "fixed !important",
                    },
                  },
                }}
                getRowId={(row) => row.companyId}
                rows={data}
                disableSelectionOnClick
                columns={columns}
                pagination
                processRowUpdate={AlterarStatus}
                onProcessRowUpdateError={handleProcessRowUpdateError}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
