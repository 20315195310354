import {
  DataGridPremium,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarExportContainer,
  GridCsvExportMenuItem,
  GridPrintExportMenuItem,
  GridExcelExportMenuItem,
} from "@mui/x-data-grid-premium";
import { publicRequest, urlAdminMooda, urlMooda } from "../../requestMethods";
import { ptBR as ptBRCore } from "@mui/x-data-grid/locales";
import * as React from "react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import Loading from "../../components/loading/loading";
import "../../global.css";
import { userRequest } from "../../requestMethods";
import Switch from "@mui/material/Switch";

export default function BotList() {
  const [data, setData] = useState([]);
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [companies, setCompanies] = useState();

  useEffect(() => {
    getBots();
    getCompanies();
  }, []);

  const getBots = async () => {
    try {
      setIsLoading(true);
      const res = await userRequest.get(`standard/bots`);

      setData(res.data);
    } catch (error) {
      console.error(error.code);
    }
    setIsLoading(false);
  };

  const deleteBot = async (number, status) => {
    const res = await userRequest.put(`standard/bots/${number}`, {
      enabled: status,
    });

    if (res.data > 0) {
      getBots();
    } else {
      Swal.fire("Atenção!", "Erro ao inativar bot!", "error");
    }
  };

  const getCompanies = async () => {
    const res = await publicRequest.get(`/company`);

    setCompanies(res.data);
  };
  const getUsers = async (companyId) => {
    try {
      const res = await userRequest.get(`${companyId}/users`);

      setUsers(res.data);
    } catch (error) {
      console.error(error.code);
    }
    setIsLoading(false);
  };
  const handleChangeCompany = (e) => {
    getUsers(e.target.value);
  };
  const onButtonDeleteClick = (e, row) => {
    if (!e.target.checked) {
      Swal.fire({
        title: "Tem certeza que deseja inativar?",
        text: "Você não poderá reverter isso!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#EE8253",
        confirmButtonText: "Sim, inativar!",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
      }).then(async (result) => {
        if (result.isConfirmed) {
          deleteBot(row, false);
        } else {
          getBots();
        }
      });
    } else {
      deleteBot(row, true);
    }
  };
  const handleProcessRowUpdateError = React.useCallback((error) => {
    console.error("error", error);
  }, []);
  const useFakeMutation = () => {
    return React.useCallback(
      (obj) =>
        new Promise(async (resolve, reject) => {
          if (obj.companyId > 0) {
            const res = await userRequest.put(
              `${obj.companyId}/users/${obj.userId}`,
              {
                authorizationBot:
                  obj.authorizationBot === ""
                    ? null
                    : obj.authorizationBot.trim(),
              }
            );
            if (Object.keys(res.data).length > 0) {
              resolve(obj);
              Swal.fire(
                "Parabéns!",
                "Webhook alterado com sucesso!",
                "success"
              );
            } else {
              reject(new Error("Erro atualizar Webhook"));
            }
          } else {
            Swal.fire("Atenção!", "Favor selecionar a empresa!", "warning");
          }
        }),
      []
    );
  };
  const mutateRow = useFakeMutation();
  const AlterarLabel = React.useCallback(async (obj) => {
    const res = await mutateRow(obj);
    return obj;
  }, []);
  const columns = [
    {
      field: "number",
      headerName: "Número",
      width: 150,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
    },
    {
      field: "enabled",
      headerName: "",
      width: 80,
      align: "center",
      headerClassName: "dataGridMuiHeader",
      headerAlign: "center",
      display: "flex",
      renderCell: (params) => {
        if (params.row.enabled) {
          return (
            <Switch
              defaultChecked={true}
              onChange={(e) => onButtonDeleteClick(e, params.row.number)}
            />
          );
        } else {
          return (
            <Switch
              defaultChecked={false}
              onChange={(e) => onButtonDeleteClick(e, params.row.number)}
            />
          );
        }
      },
    },
  ];
  const columnsUser = [
    {
      field: "userId",
      headerName: "Cod. Usuário",
      width: 150,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
    },
    {
      field: "name",
      headerName: "Nome",
      width: 200,
      align: "left",
      headerAlign: "left",
      headerClassName: "dataGridMuiHeader",
    },
    {
      field: "contact",
      headerName: "Contato",
      width: 150,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
    },
    {
      field: "authorizationBot",
      headerName: "Webhook",
      width: 300,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
      editable: true,
    },
  ];
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }
  const GridToolbarExport = ({
    csvOptions,
    printOptions,
    excelOptions,
    ...other
  }) => (
    <GridToolbarExportContainer {...other}>
      <GridCsvExportMenuItem options={csvOptions} />
      <GridExcelExportMenuItem options={excelOptions} />
    </GridToolbarExportContainer>
  );
  return (
    <div className="div_custom_">
      {isLoading ? (
        <Loading />
      ) : (
        <div className="div_custom_UpdateList">
          <div className="div_custom_UpdateLeft">
            <div style={{ height: 200 }}>
              <DataGridPremium
                autoHeight
                sx={{
                  fontFamily: "Manrope",
                  fontWeight: "500",
                  border: "0px",
                }}
                rowHeight={45}
                localeText={
                  ptBRCore.components.MuiDataGrid.defaultProps.localeText
                }
                slots={{ toolbar: CustomToolbar }}
                slotProps={{
                  panel: {
                    sx: {
                      top: "15px !important",
                      left: "50px !important",
                      position: "fixed !important",
                    },
                  },
                }}
                getRowId={(row) => row.number}
                rows={data}
                disableSelectionOnClick
                columns={columns}
                pagination
              />
            </div>
          </div>
          <div className="div_custom_UpdateItem">
            <label>Empresas</label>
            <select
              className="div_custom_Select"
              name="company"
              onChange={handleChangeCompany}
            >
              <option value=""></option>
              {companies
                ? companies.map((item) => (
                    <option value={item.companyId}>{item.name}</option>
                  ))
                : false}
            </select>
          </div>
          <div className="div_custom_UpdateLeft">
            <div style={{ height: 200 }}>
              <DataGridPremium
                autoHeight
                sx={{
                  fontFamily: "Manrope",
                  fontWeight: "500",
                  border: "0px",
                }}
                rowHeight={45}
                localeText={
                  ptBRCore.components.MuiDataGrid.defaultProps.localeText
                }
                slots={{ toolbar: CustomToolbar }}
                slotProps={{
                  panel: {
                    sx: {
                      top: "15px !important",
                      left: "50px !important",
                      position: "fixed !important",
                    },
                  },
                }}
                getRowId={(row) => row.userId}
                rows={users}
                columns={columnsUser}
                pagination
                autoPageSize
                processRowUpdate={AlterarLabel}
                onProcessRowUpdateError={handleProcessRowUpdateError}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
